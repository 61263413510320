<template>
  <div class="home">
    <!-- <img src="@/assets/FMlogo.svg" alt="flexomask logo" /> -->
    <Aboutsaas msg="Welcome to Flexomask" />
  </div>
</template>

<script>
// @ is an alias to /src
import Aboutsaas from "@/components/Aboutsaas.vue";

export default {
  name: "Login",
  components: {
    Aboutsaas,
  },
};
</script>
<style scoped>
img {
  width: 300px;
}
</style>
