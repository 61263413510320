<template>
  <div class="narrative">
    <h2>{{ msg }} Beta 0.97.2</h2>

    <b> How to use</b><br />
    <ol>
      <li>
        Save your original design as a <b>print ready Pdf in Illustrator</b>,
        please make sure to embed fonts and any placed art
      </li>
      <li>Upload the file using the drag and drop interface</li>
      <li>Download the Masks File</li>
    </ol>
    We support printready PDF files made with Adobe Illustrator 2019, 2020, 2021
    and 2022
    <div>
      <p>
        <br />
        Flexomask is a SAAS (Software As A Service) cloud based application that
        creates back masks for the corrugated carton market. We take your file
        and make a masks file with the name of Yourfilename_mk.pdf.
        <br /><br />
        With Flexomask, you only pay for what you use. There is a flat fee for
        each Job submitted based on the number of masks required. However if you
        submit the same job multiple times, you will be billed for each
        submission, since we will assume you have made revisions to the original
        file and it needs revised masks.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Aboutsaas",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  color: #326599;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  /* display: inline-block; */
  margin: 0 10px;
}
a {
  color: #42b983;
}
.narrative {
  width: 40rem;
  margin: auto;
  text-align: left;
  background-color: #ddd;
  padding: 2rem;
  border-radius: 0.5rem;
}
</style>
